/* eslint-disable no-unused-vars */
import 'core-js/stable'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import apolloClient from './apolloClient'
import VueApollo from 'vue-apollo'
import { createVuetify } from 'vuetify'
import i18n from "./i18n";
import A3MAuth from './plugins/A3MAuth'
import RepositoriesPlugin from './plugins/Repositories'
import filters from './filters';
import 'vuetify/styles'
import { aliases, md } from 'vuetify/iconsets/md'
import {Vuetify3Dialog} from 'vuetify3-dialog'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
//import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'leaflet/dist/leaflet.css';
import './libs/MoveableVuetifyDialog'
import './libs/MoveableVuetifyDialog.css';
import MarqueeText from 'vue-marquee-text-component'

let language = 'de'
if (localStorage.getItem("language")) {
  language = localStorage.getItem("language")
}

const app = createApp(App)

app.use(A3MAuth)
app.use(RepositoriesPlugin, store)
app.component('marquee-text', MarqueeText)

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $loadingKey: 'loading'
  }
})

app.use(apolloProvider)
app.use(router)
app.use(store)
app.use(i18n)

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md,
    },
  },
})

app.use(vuetify)
app.use(Vuetify3Dialog)

app.config.globalProperties.$filters = filters

app.mount('#app')

/* Init some stores, so that they can load content from the localStorage */
store.dispatch("authStore/initStore")
store.dispatch("uiStore/initStore")

